@import '../../styles/includes.scss';

.RibbonLink {
    font-family: $fontBrand;
    position: relative;

    &__Link {
        color: $colorWhite;
        text-decoration: none;
        display: block;
        background-color: $colorPink700;
        padding: 4rem 0 4rem;
        margin-top: 0.8rem;
        transition: all 0.4s;

        &:hover,
        &:focus {
            background-color: $colorPink;
        }
    }

    &__Grid {
        @extend %container;
    }

    &__TextContainer {
        max-width: $maxWidthText;
    }

    &__HeadingAndIconWrapper {
        display: flex;
        align-items: center;
    }

    &__Heading {
        @extend %h2;
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            width: 21px;
            height: 16px;
            background-image: url('#{$assetPath}img/arrow-white.svg');
            background-repeat: no-repeat;
            background-size: auto 100%;
            margin-left: 12px;
            transition: all 0.4s;

            .RibbonLink__Link:hover & {
                margin-left: 24px;
            }
        }
    }

    &__Text {
        font-weight: 400;
    }

    &__Preamble {
        @extend %preamble;
        margin-bottom: 0;
    }
}
